@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root,
canvas {
  margin: 0 auto;
  min-height: 100vh;
  /* overflow: hidden; */
}
#lazy-load-poster {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background-image: url('../../public/images/loading.gif');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.text-loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.box-arrow {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  border: 2px solid transparent;
  background: linear-gradient(
      #ffffff 13.44%,
      rgba(255, 255, 255, 0) 50.74%,
      #ffffff 86.59%
    )
    border-box;
}

.arrow {
  width: 50px;
  height: 50px;
  padding: 0.5rem;
  border-radius: 50%;
  border: 3px solid;
  background: linear-gradient(90deg, #4ba9ff 0%, #c54bff 100%);
  color: white;
}

.box-arrow:hover,
.box-arrow:focus,
.box-arrow:active {
  background: linear-gradient(90deg, #4ba9ff 0%, #c54bff 100%);
}
.box-arrow:hover .arrow,
.box-arrow:focus .arrow,
.box-arrow:active .arrow {
  background: white;
}

.box-arrow:hover svg,
.box-arrow:focus svg,
.box-arrow:active svg {
  background: transparent;
  color: #c54bff;
}
