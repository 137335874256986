@import '~bootstrap/scss/bootstrap';

.text-primary {
  color: #096dd9;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  padding: 10px 0;
}

.slider {
  width: 100%;
  text-align: center;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  padding-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .text-primary {
    padding: 20px 0;
  }

  .slider {
    bottom: 30px;
  }
}

.splide__arrow {
  background: #0072ce;
  svg {
    fill: #fff;
  }
}

.splide__arrow--prev {
  left: -1em;
}

.splide__arrow--next {
  right: -1em;
}

.opacity-div {
  background-color: #eee;

  @media screen and (max-width: 640px) {
    background-color: #dfdfdf30;
  }
}

.storyteller {
  max-width: 400px;
  font-weight: 600;
  // font-family: 'Sawarabi Mincho', serif;
  font-size: 14px;
  padding: 8px;
  border-radius: 8px;
  margin-top: 20px;
  background-color: #fff;
  color: black !important;
  font-family: 'Open Sans', sans-serif;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;

  @media screen and (min-width: 768px) {
    font-size: 16px;
  }

  @media screen and (min-width: 1200px) {
    padding-top: 80px;
    font-size: 20px;
    padding: 16px 24px;
    border-radius: 16px;
  }
}
